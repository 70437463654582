(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.informes')
    .controller('informesCtrl', informesCtrl);

  function informesCtrl(resultats, partits,$scope,$state,$mdDialog, $http,EnvironmentConfig) {
   var vm = this;
  
   $scope.partidos=[[]];
   var b=[[]];
   
   partits.forEach(function(p,i){
      //a[i]= resultats.query({idPartit: p.idPartido},function(){
        // b[i].elocal=a[i].elocal;
      // });
      $http({
                            url: EnvironmentConfig.API+"/resultatapp/"+p.idPartido,
                            method: "GET"
                        }).success(function (data, status, headers, config) {
                            
                            //copy the data we get to our items array. we need to use angular.copy so that
                            //angular can track the object and bind it automatically.
                            //angular.copy(data, $scope.partidos);
                            var b1=[];
                           ;
                            b1.apellido=p.apellido;
                          
                            if (p.email===null){
                                 b1.nombre="tècnic/a Ajuntament";
                                 b1.email=p.poblacion;
                            }else{
                                 b1.nombre=p.nombre;
                                 b1.email=p.email;
                               }
                           
                            b1.poblacion=p.poblacion;
                             var  local=0;
                             var visitant=1;
                             if (data[0].idEquipo===data[0].idLocal){
                                    local=0;
                                    visitant=1;
                                        } else{
                                             local=1;
                                             visitant=0;
                                }
                            b1.tecnicL=data[local].pdt;
                            b1.idPartido=data[local].idPartido;
                            b1.campionat=data[local].campionat;
                            b1.elocal=data[local].elocal;
                            b1.evisitant=data[local].evisitant;
                            b1.tecnicV=data[visitant].pdt;
                            b1.publicL=data[local].pdp;
                            b1.publicV=data[visitant].pdp;
                            b1.puntsvpL=data[local].verdplay;
                            b1.puntsvpV=data[visitant].verdplay;
                            b1.tvL=data[local].tv;
                            b1.tvV=data[visitant].tv;
                            b1.fecha=data[local].fecha;
                               $scope.partidos.push(b1);
                               
                        }).error(function (data, status, headers, config) {
                            //something went wrong
                           
                        });
                        
     
   });
   // angular.copy(a, $scope.partidos);
   
  }
}());


/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


